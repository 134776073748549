"use client"

import { Button } from "@/components/ui/button"
import { SidebarTrigger, useSidebar } from "@/components/ui/sidebar"
import { cn } from "@/lib/utils"
import {
  SignedIn,
  SignedOut,
  SignInButton,
  useClerk,
  UserButton,
  useUser,
} from "@clerk/nextjs"
import { Home, MenuIcon, Settings, UserIcon } from "lucide-react"
import Link from "next/link"
import { useRouter } from "next/navigation"

export default function NavBar() {
  const { isMobile, toggleSidebar } = useSidebar()
  const clerk = useClerk()
  const { user, isLoaded } = useUser()
  const { openUserProfile } = clerk
  const router = useRouter()
  return (
    <div
      className={cn(
        "flex flex-none flex-row items-center justify-center bg-darkBlue-1 p-2 w-full relative",
        "h-[var(--header-height)]"
      )}
    >
      <div className="absolute left-2 h-full items-center flex text-white">
        {isMobile ? <MenuIcon onClick={toggleSidebar} className="hover:cursor-pointer" /> : <SidebarTrigger />}
      </div>
      <Link href="/">
        <h2 className="text-white font-bold">ケース面接マッチングナビ</h2>
      </Link>
      <div className="absolute right-2 flex top-0 bottom-0 items-center">
        {isLoaded && (
          <>
            <SignedIn>
              <UserButton>
                <UserButton.MenuItems>
                  <UserButton.Action
                    label="プロフィールの確認・編集"
                    labelIcon={<UserIcon className="size-4" />}
                    onClick={() => {
                      router.push(`/user/${user?.id}`)
                    }}
                  />
                  <UserButton.Action
                    label="アカウント設定"
                    labelIcon={<Settings className="size-4" />}
                    onClick={() => {
                      openUserProfile()
                    }}
                  />
                  <UserButton.Link
                    label="ホームページ"
                    href="/LP"
                    labelIcon={<Home className="size-4" />}
                  />
                </UserButton.MenuItems>
              </UserButton>
            </SignedIn>
            <SignedOut>
              <SignInButton>
                <Button variant="outline" className="h-7">
                  ログイン
                </Button>
              </SignInButton>
            </SignedOut>
          </>
        )}
      </div>
    </div>
  )
}
